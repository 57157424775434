import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import IndexLayout from '../../layouts';

import Footer from '../../components/Footer/Footer';
import SEO from '../../components/SEO/SEO';

import PaymentsCarousel from '../../components/Payments/PaymentsCarousel/PaymentsCarousel';
import TransferAvailability from '../../components/Payments/Transfer/TransferAvaliablility/TransferAvaliability';
import TransferCTA from '../../components/Payments/Transfer/TransferCTA/TransferCTA';
import TransferFeatures from '../../components/Payments/Transfer/TransferFeatures/TransferFeatures';
import TransferHero from '../../components/Payments/Transfer/TransferHero/TransferHero';
import TransferSpecs from '../../components/Payments/Transfer/TransferSpecs/TransferSpecs';
import { LocaleTypes } from '../../data/locales';

interface TransferPageProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const TransferPage: React.FC<TransferPageProps> = ({ pathContext, location }) => {
  const content = () => (
    <>
      <SEO titleId="payments.transfer.title" descriptionId="payments.transfer.description" />

      <TransferHero/>

      <TransferFeatures />

      <TransferSpecs />

      <TransferCTA />

      <TransferAvailability />

      <section className="py-layout-6 overflow-hidden">
        <div className="container">
          <div className="row mb-layout-4">
            <div className="col-12 text-center">
              <p className="lead">
                <FormattedMessage id="payments.carousel.title"/>
              </p>
            </div>
          </div>

          <PaymentsCarousel list={['card', 'cash', 'smartlink']} />
        </div>
      </section>

      <Footer theme="primary" />
    </>
  );

  return <IndexLayout render={ content } locale={pathContext.localeCode} location={location} />
}

export default TransferPage;
