import React from 'react';
import { FormattedMessage } from 'react-intl';
import { defaultFormatValues } from '../../../../data/defaultFormat';

import bg1Img from './assets/images/bg-1.svg';
import bg2Img from './assets/images/bg-2.svg';
import simpleImg from './assets/images/simple.svg';
import backOfficeImg from './assets/images/transfer-backoffice.svg';

import './styles.scss';

const TransferFeatures: React.FC = ({ children }) => (
<div className="py-layout-5 overflow-hidden">
  <section className="py-layout-5">
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <h1 className="text-primary">
            <FormattedMessage id="payments.transfer.benefits.benefit_1.title"/>
          </h1>
          <div className="line-separator--brand-green" />
          <p className="lead mt-5" style={{whiteSpace: 'pre-wrap'}}>
            <FormattedMessage id="payments.transfer.benefits.benefit_1.subtitle"
              values={defaultFormatValues}
            />
          </p>
        </div>
        <div className="col-lg-6 text-center">
          <div className="position-relative">
            <img src={bg1Img} className="transfer-features-bg-1" />
            <img src={simpleImg} alt="Improved payment experience" className="img-fluid mt-layout-3 mt-lg-0"/>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section className="py-layout-5 position-relative">
    <img src={bg2Img} className="transfer-features-bg-2" />
    <div className="container">
      <div className="row">
        <div className="col-lg-6 order-2 order-lg-1 col-8 offset-2 offset-lg-0">
          <img src={backOfficeImg} alt="Backoffice" className="img-fluid mt-layout-3 mt-lg-0"/>
        </div>
        <div className="col-lg-5 offset-lg-1 order-1 order-lg-2">
          <h1 className="text-primary">
            <FormattedMessage id="payments.transfer.benefits.benefit_2.title"/>
          </h1>
          <div className="line-separator--brand-green" />
          <p className="lead mt-5" style={{whiteSpace: 'pre-wrap'}}>
            <FormattedMessage id="payments.transfer.benefits.benefit_2.subtitle"
              values={defaultFormatValues}
            />
          </p>
        </div>
      </div>
    </div>
  </section>
</div>
);

export default TransferFeatures;
