import React from 'react';
import { FormattedMessage } from 'react-intl';
import { defaultFormatValues } from '../../../../data/defaultFormat';

import SalesButton from '../../../SalesButton/SalesButton';

import heroBgImg from './assets/images/hero-bg.svg';
import HeroImg from './assets/images/hero.inline.svg';

import './styles.scss';

const TransferHero: React.FC = ({ children }) => (
  <section
    className="hero overflow-visible hero-payments-transfer"
    style={{backgroundImage: `url(${heroBgImg})`}}
  >
    <div className="container">
      <div className="row align-items-center">
        <div className="col-md-6">
          <HeroImg className="img-fluid"/>
        </div>
        <div className="col-md-6">
          <h1 className="display-4 mb-layout-2 text-primary">
            <FormattedMessage id="payments.transfer.hero.title"/>
          </h1>
          <p className="lead text-dark-gray-3" style={{whiteSpace: 'pre-wrap'}}>
            <FormattedMessage id="payments.transfer.hero.subtitle"
              values={defaultFormatValues}
            />
          </p>
          <div className="button-responsive-row mt-2">
            <SalesButton big={true} positionTag="top">
              <FormattedMessage id="buttons.sales"/>
            </SalesButton>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default TransferHero;
