import React from 'react';
import { FormattedMessage } from 'react-intl';
import { defaultFormatValues } from '../../../../data/defaultFormat';

import accessibilityIcon from './assets/images/accessibility.svg';
import commissionIcon from './assets/images/commission.svg';
import controlIcon from './assets/images/control.svg';
import trustIcon from './assets/images/trust.svg';

import './styles.scss';

const TransferSpecs: React.FC = ({ children }) => (
  <>
  <section>
    <div className="py-layout-7 bg-light-gray-1-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <img src={accessibilityIcon} alt="accessibility img" className="mb-4 mt-5 mt-md-0" />
            <h4 className="mb-4 text-primary">
              <FormattedMessage id="payments.transfer.features.feature_1.title"/>
            </h4>
            <p className="text-dark-gray-2">
              <FormattedMessage id="payments.transfer.features.feature_1.subtitle"
                values={defaultFormatValues}
              />
            </p>
          </div>
          <div className="col-lg-3 col-md-6">
            <img src={controlIcon} alt="control img" className="mb-4 mt-5 mt-md-0" />
            <h4 className="mb-4 text-primary">
              <FormattedMessage id="payments.transfer.features.feature_2.title"/>
            </h4>
            <p className="text-dark-gray-2">
              <FormattedMessage id="payments.transfer.features.feature_2.subtitle"
                values={defaultFormatValues}
              />
            </p>
          </div>
          <div className="col-lg-3 col-md-6">
            <img src={trustIcon} alt="trust img" className="mb-4 mt-5 mt-md-0" />
            <h4 className="mb-4 text-primary">
              <FormattedMessage id="payments.transfer.features.feature_3.title"/>
            </h4>
            <p className="text-dark-gray-2">
              <FormattedMessage id="payments.transfer.features.feature_3.subtitle"
                values={defaultFormatValues}
              />
            </p>
          </div>
          <div className="col-lg-3 col-md-6">
            <img src={commissionIcon} alt="experience img" className="mb-4 mt-5 mt-md-0" />
            <h4 className="mb-4 text-primary">
              <FormattedMessage id="payments.transfer.features.feature_4.title"/>
            </h4>
            <p className="text-dark-gray-2">
              <FormattedMessage id="payments.transfer.features.feature_4.subtitle"
                values={defaultFormatValues}
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  </>
);

export default TransferSpecs;
